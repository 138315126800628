import React, { useMemo } from "react"
import Layout from "../components/layout"
import { useHousesList } from "../hooks"
import { CityHousesCard } from "../components/card"
import styles from "./houses.module.scss"
import { formatPrice } from "../utils/format"
import { calcCityHouses } from "../utils"

const HousesPage = ({ location }) => {
  const houses = useHousesList(1000)

  const cityHouses = useMemo(() => calcCityHouses(houses), [houses])
  const housesThumbs = useMemo(
    () => houses.map(h => h.thumbnail).filter(t => t),
    [houses]
  )
  const commonMinPrice = useMemo(
    () =>
      Math.min(
        ...houses
          .filter(h => !h.archived)
          .map(h => h.price)
          .filter(price => price)
      ),
    [houses]
  )

  const commonMinArea = useMemo(
    () =>
      Math.min(
        ...houses
          .filter(h => !h.archived)
          .map(h => h.area)
          .filter(area => area)
      ),
    [houses]
  )

  const commonMaxArea = useMemo(
    () =>
      Math.max(
        ...houses
          .filter(h => !h.archived)
          .map(h => h.area)
          .filter(area => area)
      ),
    [houses]
  )

  const cities = [
    {
      slug: "all-houses",
      name: "Всі будинки",
      count: houses.length,
      liveCount: houses.filter(h => !h.archived).length,
      minPrice: commonMinPrice,
      minArea: commonMinArea,
      maxArea: commonMaxArea,
      thumbnail: housesThumbs[Math.floor(housesThumbs.length * Math.random())],
    },
    ...cityHouses,
  ]

  const title = "Купити готовий будинок " + new Date().getFullYear()
  const description = `${cityHouses.map(c => c.name).join(", ")}`

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      location={location}
    >
      <h1>Будинки</h1>
      <div className={styles.cityList}>
        {cities.map(city => (
          <CityHousesCard city={city} key={city.slug} />
        ))}
      </div>
      <br />
      <p className={styles.bottomText}>
        Купити комфортний, новий, цегляний будинок поблизу Києва від{" "}
        <b>{formatPrice(commonMinPrice)}$</b>.{" "}
        {cityHouses
          .sort((a, b) => b.liveCount - a.liveCount)
          .map(c => c.name)
          .join(", ")}
      </p>
    </Layout>
  )
}

export default HousesPage
